// `usePageContext` allows us to access `pageContext` in any Vue component.
// See https://vite-plugin-ssr.com/pageContext-anywhere

import { inject, UnwrapNestedRefs } from 'vue';
import type { App, InjectionKey } from 'vue';
import { PageContext } from '#root/src/renderer/types';

let key: InjectionKey<PageContext> = Symbol('pageContext');

export const pageContextKey = key;

function usePageContext() {
  const pageContext = inject(key);
  if (!pageContext) throw new Error('setPageContext() not called in parent');
  return pageContext;
}

function setPageContext(app: App, pageContext: UnwrapNestedRefs<PageContext>) {
  app.provide(key, pageContext);
}

export function updatePageContextKeyForTest(k: string) {
  key = Symbol(k);
  return key;
}

export { usePageContext };
export { setPageContext };
